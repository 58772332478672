<template>
    <div>
        <v-dialog v-model="$store.state.dialog" :fullscreen="isfullscreen" width="70%" persistent transition="dialog-top-transition">
            <v-card flat class="card">
                <v-toolbar outlined flat color="grey lighten-4">
                    <v-toolbar-title>{{$store.state.itemDetails.dialogType == 'add' ? 'إضافة' : 'تعديل'}} {{this.$route.meta.single}}</v-toolbar-title>
                    <v-spacer />
                    <v-btn icon @click="isfullscreen = !isfullscreen">
                        <v-icon small>{{ isfullscreen ? "mdi-arrow-collapse" : "mdi-arrow-expand" }}</v-icon>
                    </v-btn>
                    <v-btn @click="close()" icon> <v-icon>close</v-icon> </v-btn>
                </v-toolbar>

                <v-form @submit.prevent="submit()" ref="form" lazy-validation v-model="valid"> 
                    <v-container>
                        <v-card outlined>
                            <v-container>
                                <v-row>

                                    <!-- clientId -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            الزبون
                                            <span class="required">*</span>
                                        </h4>
                                        <v-autocomplete
                                            v-model="item.fromUserId"
                                            :items="clients"
                                            :loading="$global.state.loading"
                                            :rules="[$global.state.required()]"
                                            item-text="fullName"
                                            item-value="id"
                                            color="info"
                                            placeholder="الزبون"
                                            no-data-text="لا توجد بيانات"
                                            hide-details="auto"
                                            prepend-inner-icon="fi fi-br-users"
                                            outlined
                                            filled
                                        />
                                        
                                    </v-col>

                                    <!-- orderName -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            اسم الطلبية
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.orderName"
                                            placeholder="طلبية صيدلية الكوثر"
                                            :rules="[$global.state.required()]"
                                            required
                                            color="info"
                                            filled
                                            outlined
                                            hide-details="auto"/>
                                    </v-col>

                                    <!-- deliveryAddress -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            عنوان التوصيل
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.deliveryAddress"
                                            placeholder="النجف الاشرف"
                                            :rules="[$global.state.required()]"
                                            required
                                            color="info"
                                            filled
                                            outlined
                                            hide-details="auto"/>
                                    </v-col>

                                    <!-- recipientPhoneNo1 -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            رقم هاتف المستلم 1
                                            <span class="required">*</span>
                                        </h4>
                                        <v-text-field
                                            v-model="item.recipientPhoneNo1"
                                            v-mask="$phoneMask"
                                            dir="ltr"
                                            color="info"
                                            placeholder="+964 ### ### ####"
                                            :rules="[$global.state.required()]"
                                            required
                                            filled
                                            outlined
                                            hide-details="auto"/>
                                    </v-col>

                                    <!-- recipientPhoneNo2 -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">
                                            رقم هاتف المستلم 2
                                        </h4>
                                        <v-text-field
                                            v-model="item.recipientPhoneNo2"
                                            v-mask="$phoneMask"
                                            dir="ltr"
                                            color="info"
                                            placeholder="+964 ### ### ####"
                                            filled
                                            outlined
                                            hide-details="auto"/>
                                    </v-col>
                                   
                                    <!-- imageUrl -->
                                    <v-col cols="12" md="6">
                                        <h4 class="mb-2">صورة</h4>
                                        <FileUpload accept="image/*;capture=camera" formData="files" endPoint="UploadFile/postUploadFiles" buttonText="رفع صورة" icon="true"/>
                                    </v-col>
                                 
                                </v-row>


                                <!-- <v-divider class="mt-5 my-2" />

                                <v-btn color="primary secondary--text" @click="addProduct()">
                                    <h3>إضافة قياس</h3>
                                </v-btn> -->

                                <!-- products -->
                                <v-card flat class="grey lighten-4 pa-2 mt-5" v-for="(product, index) in item.carts" :key="index">
                                    <!-- <v-btn class="btn-remove-productSize" @click="remove(index)" color="error" icon>
                                        <v-icon>delete</v-icon>
                                    </v-btn> -->
                                    <v-row>

                                        <!-- categoryId  -->
                                        <v-col cols="12" md="4">
                                            <h4 class="mb-2">القسم</h4>
                                            <v-autocomplete
                                                clearable
                                                v-model="$global.state.categoryId"
                                                @input="getProducts"
                                                item-text="name"
                                                item-value="id"
                                                :items="$global.state.listCategories"
                                                placeholder="القسم"
                                                color="info"
                                                :loading="$global.state.loading"
                                                no-data-text="لا توجد بيانات"
                                                hide-details="auto"
                                                outlined required filled/>
                                        </v-col>

                                        <!-- productId  -->
                                        <v-col cols="12" md="4">
                                            <h4 class="mb-2">المنتج</h4>
                                            <v-autocomplete 
                                                clearable outlined 
                                                :items="$global.state.products" 
                                                v-model="selectedproduct"
                                                filled return-object
                                                hide-details="auto"
                                                placeholder="المنتج"  
                                                color="info">
                                                <template v-slot:selection="data">
                                                    <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
                                                        <v-avatar left>
                                                            <v-img :src="data.item.mainImageUrl"></v-img>
                                                        </v-avatar>
                                                        {{ data.item.name }}
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="typeof data.item !== 'object'
                                                        ">
                                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                        <v-list-item-avatar color="primary">
                                                            <img :src="data.item.mainImageUrl" />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-html="data.item.description"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>

                                        <!-- productSizeId  -->
                                        <v-col cols="12" md="4">
                                            <h4 class="mb-2">القياس</h4>
                                            <v-autocomplete 
                                                clearable outlined 
                                                :items="selectedproduct.sizes" 
                                                v-model="selectedproductSize"
                                                @input="setProductSize()"
                                                filled return-object
                                                hide-details="auto"
                                                placeholder="قياس 15 * 20"
                                                color="info">
                                                <template v-slot:selection="data">
                                                    <v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select">
                                                        <v-avatar left>
                                                            <v-img :src="selectedproduct.mainImageUrl"></v-img>
                                                        </v-avatar>
                                                        {{ data.item.name }}
                                                    </v-chip>
                                                </template>
                                                <template v-slot:item="data">
                                                    <template v-if="typeof data.item !== 'object'
                                                        ">
                                                        <v-list-item-content v-text="data.item"></v-list-item-content>
                                                    </template>
                                                    <template v-else>
                                                        <v-list-item-avatar color="primary">
                                                            <img :src="selectedproduct.mainImageUrl" />
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                                            <v-list-item-subtitle> {{ data.item.price | formatCurrency }}</v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </template>
                                                </template>
                                            </v-autocomplete>
                                        </v-col>
                                        
                                        <!-- qty -->
                                        <v-col cols="12" md="4">
                                            <h4 class="mb-2">الكمية
                                                <span class="required">*</span>
                                            </h4>
                                            <v-text-field
                                                type="number"
                                                :rules="[$global.state.required()]"
                                                v-model="item.carts[0].qty"
                                                placeholder="الكمية"
                                                min="1"
                                                filled
                                                outlined
                                                hide-details="auto"
                                            />
                                        </v-col>

                                        <!-- price -->
                                        <v-col cols="12" md="4">
                                            <h4 class="mb-2">السعر
                                                <span class="required">*</span>
                                            </h4>
                                            <v-text-field
                                                v-model="item.carts[0].price"
                                                @input="item.carts[0].price = $genericService.formatCurrency(item.carts[0].price)"
                                                :rules="[$global.state.required()]"
                                                placeholder="0.0"
                                                suffix="IQD"
                                                filled
                                                outlined
                                                hide-details="auto"
                                                required
                                            />
                                        </v-col>

                                        <!-- discount -->
                                        <v-col cols="12" md="4">
                                            <h4 class="mb-2">
                                                الخصم ٪
                                                <span class="required">*</span>
                                            </h4>
                                            <v-text-field
                                                v-model="item.carts[0].discount"
                                                :rules="[$global.state.maxNumber(100), $global.state.minNumber(0)]"
                                                suffix="%"
                                                type="number"
                                                :min="0"
                                                :max="100"
                                                outlined
                                                filled
                                                color="info"
                                                hide-details="auto"
                                                required
                                            ></v-text-field>
                                        </v-col>

                                    </v-row>
                                </v-card>

                                <!-- note a-->
                                <v-col cols="12" md="12">
                                    <h4 class="mb-2">
                                        ملاحظات
                                    </h4>
                                    <v-text-field
                                        v-model="item.note"
                                        placeholder="ملاحظات"
                                        color="info"
                                        filled
                                        outlined
                                        hide-details="auto"/>
                                </v-col>

                            </v-container>
                        </v-card>
                        <!-- actions -->
                        <v-col cols="12" md="12">
                            <v-row class="mt-2">
                                <v-btn type="submit" :loading="$global.state.loading" x-large color="primary" class="btn secondary--text" :disabled="!valid">
                                    حفظ
                                </v-btn>
                                <v-btn @click="close()" x-large color="error" class="mr-2 btn" outlined> إلغاء </v-btn>
                            </v-row>
                        </v-col>

                    </v-container>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    components: {
        FileUpload: () => import("@/components/FileUpload.vue"),
    },

    data() {
        return {
            valid: false,
            isfullscreen: false,
            item: {},
            selectedproduct: {
                sizes: [],
            },
            selectedproductSize: "",
            clients: [],
        };
    },

    mounted() {
        this.reset();
        this.getListCategories();
        this.getProducts();
        this.getClients();        

        this.$eventBus.$on("fill-fields", () => {
            this.item = this.$store.state.itemDetails;
            this.item.categoryId = this.$store.state.itemDetails.category.id;
        });
    },

    methods: {
        reset() {
            this.item = {
                orderName: "",
                deliveryAddress: "",
                recipientPhoneNo1: "",
                recipientPhoneNo2: "",
                note: "",
                imageUrl: "",
                fromOffers: false,
                carts: [
                    {
                        productSizeId: "",
                        qty: 1,
                        price: 0,
                        discount: 0,
                    },
                ],
            };
            this.$store.commit("clearFiles");
        },

        submit() {
            this.$refs.form.validate()
            if (this.$refs.form.validate()) {                
                this.item.carts[0].price = typeof this.item.carts[0].price == "string" ? parseFloat(this.item.carts[0].price.replace(/,/g, "")) : this.item.carts[0].price;
                if (this.$store.state.itemDetails.dialogType == "add") {
                    this.addItem();
                } else {
                    this.editItem();
                }
            }
        },

        async addItem() {
            this.$global.state.loading = true;
            if (this.$store.state.files[0] != null) {
                this.$store.state.progress = true;
                let formData = new FormData();
                for (const i of Object.keys(this.$store.state.files)) {
                    formData.append("files", this.$store.state.files[i]);
                }
                await this.$http
                    .post("/UploadFile/postUploadFiles", formData)
                    .then((res) => {
                        this.item.imageUrl = res.data.result.fileList[0].path;
                    });
            }
            await this.$http
                .post(`${this.$route.meta.endPoint}`, this.item)
                .then((res) => {
                    this.$eventBus.$emit(`refresh`);
                    this.close();
                    this.$store.dispatch("toastification/setToast", {
                        message: `تم انشاء الطلب بنجاح`,
                        type: "success",
                    });
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", {
                        message: `${err.data.message}`,
                        type: "error",
                    });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        editItem() {
            this.$global.state.loading = true;
            this.$http
                .put(`${this.$route.meta.endPoint}/${this.item.id}`, this.item)
                .then((res) => {
                    this.close();
                    this.$store.dispatch("toastification/setToast", { message: `${res.data.message}`, type: "success" });
                    this.$eventBus.$emit(`refresh`);
                })
                .catch((err) => {
                    this.$store.dispatch("toastification/setToast", { message: `${err.data.message}`, type: "error" });
                })
                .finally(() => (this.$global.state.loading = false));
        },

        setProductSize() {
            this.item.carts[0].productSizeId = this.selectedproductSize.id;
            this.item.carts[0].qty = this.selectedproductSize.minNoSale;
            this.item.carts[0].discount = this.selectedproductSize.discount;
            this.item.carts[0].price = this.$genericService.formatCurrency(this.selectedproductSize.price)
        },

        getListCategories() {
            this.$global.dispatch(`getListCategories`);
        },

        getProducts() {
            this.$global.dispatch(`getProducts`);
        },

        async getClients() {
            try {
                this.$global.state.loading = true;
                const res = await this.$http.get(`Clients/list`)
                this.clients = res.data.result;
            } catch (err) {
                // log//
            } finally {
                this.$global.state.loading = false;
            }
        },

        addProduct() {
            this.item.carts.push({
                productSizeId: "",
                qty: "",
                price: "",
                discount: 0,
            });
        },

        remove(index) {
            this.item.carts.splice(index, 1);
        },

        close() {
            this.$store.commit("setDialog");
            this.reset();
            this.resetValidation();
            this.$store.commit("setItemDetails", {});
            this.selectedproduct = "";
            this.selectedproductSize = "";
        },

        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>


<style scoped>
.btn-remove-productSize {
    position: absolute;
    top: -15px;
    left: -15px;
}
</style>
